import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app/App";
import { createRoot } from 'react-dom/client';
import { StyleContextProvider } from "./styles/style.context";

// import "./styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.otf";
// import "./styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.otf";
// import "./styles/fonts/Inter/Inter-Medium.ttf";
// import "./styles/fonts/Inter/Inter-SemiBold.ttf";
import "./styles/fonts/Nekst/Nekst-Medium.ttf";
import "./styles/fonts/Nekst/Nekst-Regular.ttf";
import "./styles/fonts/Inter/Inter-Medium.ttf";
import "./styles/fonts/Inter/Inter-SemiBold.ttf";
import "./styles/global.css";

import './i18n';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<StyleContextProvider><App /></StyleContextProvider>);