import { styled } from "styled-components";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { colorFetch } from "../../../styles/functions";
import { lngs } from '../../../app/App';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-scroll';
import { ButtonState } from "../../../styles/common-components";
import { media } from "../../../styles/media";
import { uniqueAlphaNumericId } from "../../../utils/uniqueId";


const Button = styled(ButtonState)`
`;

const Modal = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: all 0.1s linear;
  background: rgba(9, 54, 63, 0.9);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 110px 16px 50px;

  &.open {
    transform: translateY(0);
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;
`;

const LinkScroll = styled(Link)`
  cursor: pointer;
  color: ${colorFetch("secondary")};
  font-family: HelveticaNeueCyr;
  font-size: 18px;
  font-weight: 500;
`;

const StyledLinks = styled(Links)`
cursor: pointer;
margin-bottom: 200px;
`

export const MobileMenu = ({ links, isOpenMobileMenu, setIsOpenMobileMenu }) => {
  const { i18n } = useTranslation();
  return (
    <Modal className={isOpenMobileMenu && "open"}>
      <Links>
        {links.map(({ text, url}) => (<LinkScroll key={uniqueAlphaNumericId()} onClick={()=> setIsOpenMobileMenu(false)} smooth={true} duration={500} to={url}><Trans i18nKey={`header.${url}`}>{text}</Trans></LinkScroll>))}
      </Links>
      <StyledLinks>
           {Object.keys(lngs).map((locale) => (<LinkScroll key={uniqueAlphaNumericId()} onClick={() => {
            i18n.changeLanguage(locale) 
            setIsOpenMobileMenu(false)
            }}>{lngs[locale].nativeName}</LinkScroll>))}
       </StyledLinks>
      {/* <a style={{textDecorationLine: 'none', color: '#09363F'}} target="blank" download="whitepaper" href="">
            <Button>
                  Launch app
            </Button>
          </a> */}
    </Modal>
  );
};


