import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import styled from "styled-components";
import {
  AboutUs,
  Footer,
  Header,
  HowItWorks,
  Main,
  RoleSystem,
  StartWorkWith,
} from "../components";

import { useTranslation } from 'react-i18next';

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig } from "wagmi";
import { sepolia, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiConfig } from "wagmi";


const AppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const lngs = {
  en: { nativeName: 'English' },
  ru: { nativeName: 'Russian' },
  es: { nativeName: 'Spanish' },
};

const { chains, publicClient } = configureChains(
  [polygon, sepolia],
  [publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: "IDAO-landing",
  projectId: '17dc25430b1ad3054dfce59554ea7be8',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});




export const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    smoothscroll.polyfill();
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider locale={i18n.language} chains={chains}>
        <div style={{width: '100%', height: '500px', position: 'fixed', top: '-381px',  zIndex: '-100', background: '#09363F'}}></div>
        <AppWrapper>
          <Header />
          <Main />
          <AboutUs />
          <HowItWorks />
          <RoleSystem />
          <StartWorkWith />
          <Footer />
          
        </AppWrapper>
        <div style={{width: '100%', height: '200px', position: 'fixed',  bottom: '0', zIndex: '-100', background: '#09363F'}}></div>
    </RainbowKitProvider>
    </WagmiConfig>
  );
};
