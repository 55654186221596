import { styled } from "styled-components";
import { GlobalWrapper, ButtonState } from "../../styles/common-components";
import { colorFetch } from "../../styles/functions";
import { ReactComponent as ScrollDownIcon } from "../../assets/images/scroll_down.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/arrow_right_small.svg";
import backgroundVideo from "../../assets/backgrounds/main_block_video.mp4";
import { media } from "../../styles/media";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import pitch_ru from "./assets/pitch_ru.pdf";
import pitch_en from "./assets/pitch_en.pdf";

const Background = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colorFetch("primary")};
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

 & video::-webkit-media-controls-panel {
    display: none;
    -webkit-appearance: none;
}
  & video::-webkit-media-controls-play-button {
    display: none;
    -webkit-appearance: none;
  }
  & video::-webkit-media-controls-start-playback-button {
    display: none;
    -webkit-appearance: none
  }
  & video::-webkit-media-controls {
    display: none;
    -webkit-appearance: none;
  }
`;

const ContentWrapper = styled(GlobalWrapper)`
  z-index: 1;
`;

const Title = styled.h1`
  max-width: 450px;
  width: 100%;
  color: ${colorFetch("secondary")};
  font-family: Inter;
  font-size: 92px;
  font-weight: 500;
  line-height: 102px;
  margin: 140px 0 0 200px;

  ${media.desktop`
    font-size: 40px;
    line-height: 120%; 
    margin-top: 80px;
    margin-left: 0;
  `}
`;

const Description = styled.p`
  max-width: 415px;
  width: 100%;
  color: ${colorFetch("secondary")};
  font-family: HelveticaNeueCyr;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  margin: 26px 0 0 200px;

  ${media.desktop`
    font-size: 18px;
    line-height: 150%;
    margin-top: 8px;
    margin-left: 0;
  `}
`;

const Button = styled(ButtonState)`
  margin: 44px 0 164px 200px;

  ${media.desktop`
    padding: 14px 56px;
    margin-top: 16px;
    margin-left: 0;
  `}
`;

const ButtonText = styled.span`
  margin-right: 10px;
`;

const ScrollDown = styled.div`
  margin-bottom: 74px;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  `}
`;

const ScrollDownText = styled.p`
  margin-top: 10px;
  color: ${colorFetch("secondary")};
  font-family: HelveticaNeueCyr;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  ${media.desktop`
    text-align: center;
  `}
`;

const WrapperScrollDownIcon = styled(ScrollDownIcon)`
cursor: pointer;
`

export const Main = () => {
  const { t, i18n } = useTranslation();
  return (
    <Background id='main'>
      <ContentWrapper>
        <Title>{t("main.title")}</Title>
       
       <Description>
       {t("main.description")}
        </Description>

        <a style={{textDecorationLine: 'none', color: '#09363F'}} target="blank" download="description" href={i18n.language ==='ru' ? pitch_ru : pitch_en }>
       <Button>
          <ButtonText>{t("main.btn")}</ButtonText>
          <ArrowRightIcon />
        </Button>
        </a>
       <ScrollDown>
          
          <Link to={'whatIs'} smooth={true} duration={500}>
            <WrapperScrollDownIcon />
          </Link>
          <ScrollDownText>
          {t("main.scrollDownText")} 
          </ScrollDownText>
        </ScrollDown>
      </ContentWrapper>

      <Video autoplay='autoplay' loop='loop' muted='muted' loading="lazy" playsinline='' style={{pointerEvents: 'none'}}>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
    </Background>
  );
};