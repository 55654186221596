import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
          
          en: {
            translation: {
              header: {
                whatIs: 'Who we are',
                howItWork: 'How it works',
                dao: 'DAO',
                roadMap: 'Roadmap',
                tokenomikcs: 'Tokenomics',
              },
              main: {
                title: 'Predict Trust Earn',
                description: 'Make cryptocyrrency prices predictions and receive rewards with IDAO Forecast',
                btn:'Project Description',
                scrollDownText: 'Safe financial ecosystem',
              },
              aboutUs: {
                title: 'What is IDAO',
                description: 'IDAO is decentralized financial ecosystem governed in the form of DAO',
                card1: {
                    title: 'IDAO forecast',
                    description: 'IDAO Forecast allows to make cryptocyrrency prices predictions, receive rewards and gain an investment rating.'
                },
                card2: {
                    title: 'IDAO trust',
                    description: 'IDAO Trust allows to choose an investment strategy and transfer funds to trust management for profits.'
                },
                card3: {
                    title: 'IDAO vote',
                    description: 'Allows IDAO token holders to collectively make key decisions and influence the future of the IDAO protocol.'
                },
              },
              howItWork: {
                title: 'How it works',
                block1: {
                    pointTitle: 'Proof of Success',
                    title: 'Forecasts',
                    description: 'Make cryptocyrrency prices predictions, receive rewards, gain an investment rating and participate in competitions on IDAO Forecast.',
                    iconTitle1: 'Real-time analytics',
                    iconTitle2: 'Rating gaining',
                    iconTitle3: 'Participation encouraging',
                },
                block2: {
                    pointTitle: 'Investment growth',
                    title: 'Staking',
                    description: 'Receive rewards for staking tokens, access to advanced predictions possibilities on IDAO Forecast and participations in voting on IDAO Vote.',
                    iconTitle1: 'Participation in development',
                    iconTitle2: 'Extended capabilities ',
                    iconTitle3: 'Percentage income',
                },
                block3: {
                    pointTitle: 'Trust managment',
                    title: 'Skills monetization',
                    description: 'Demonstrate your skills globally, become one of the top IDAO Forecast analysts and get the opportunity to become a trust manager on IDAO Trust.',
                    iconTitle1: 'Personal rating',
                    iconTitle2: 'Trust manager status ',
                    iconTitle3: 'Commission income',
                },
                block4: {
                    pointTitle: 'Governance',
                    title: 'Voting',
                    description: 'IDAO Vote is a necessary mechanism for a decentralized organization and allows token owners to participate in decision-making.',
                    iconTitle1: 'Decentralization of decisions',
                    iconTitle2: 'Personal involvement',
                    iconTitle3: 'Protection of interests',
                },
              },
              dao: {
                title: 'DAO and Role System',
                description: 'Decentralized Autonomous Organization (DAO) is an organization built on blockchain that functions on the basis of smart-contracts and allows participants to participate in decision-making and manage the organizations without a central counterparty (authority).',
                block1: {
                    title: 'Control',
                    description: "IDAO is fully controlled by IDAO token holder. They influence the protocol development and determine its key parameters that insure financial stability."
                },
                block2: {
                    title: 'Role System',
                    description: 'IDAO has a role system with directors making proposals for community consideration. Community decides whether to accept or reject the proposal by voting.'
                },
              },
              roadMap: {
                title: 'Road Map',
                desktop: {
                  map1: {
                    block1: {
                      title: '1 quarter 2024',
                      description: 'IDAO Forecast prototype',
                    },
                    block2: {
                      title: '2 quarter 2024',
                      description: 'Beta version of the platform',
                    },
                    block3: {
                      title: '3 quarter 2024',
                      description: 'Launch IDAO Forecast',
                    },
                  },
                  map2: {
                    block1: {
                      title: '4 quarter 2024',
                      description: 'Commission and liquidity pools',
                    },
                    block2: {
                      title: '1-2 quarter 2025',
                      description: 'IDAO Trust Beta',
                    },
                    block3: {
                      title: '3 quarter 2025',
                      description: 'Launching IDAO Trust',
                    },
                  },
                },
                mobile: {
                  block1: {
                    title: 'End 2023',
                    description: 'IDAO Forecast prototype',
                  },
                  block2: {
                    title: 'Q1 2024',
                    description: 'Beta version of the platform',
                  },
                  block3: {
                    title: '2-3 quarter 2024',
                    description: 'Launch IDAO Forecast',
                  },
                  block4: {
                    title: 'Q4 2024',
                    description: 'Commission and liquidity pools',
                  },
                  block5: {
                    title: 'Q1 2025',
                    description: 'IDAO Trust Beta',
                  },
                  block6: {
                    title: '2-3 quarter 2025',
                    description: 'Launching IDAO Trust',
                  },
                },
              },
              tokenomikcs: {
                title: 'Tokenomics',
                general:'General offer',
                text1: 'Team',
                text2: 'Community',
                text3: 'Investors',
                text4: 'Marketing',
                text5: 'Staking rewards',
                text6: 'Forecast rewards',
                text7: 'Other (needs)',
                text8: 'Liquidity',
              },
              startWorkWith: {
                title: 'Get started with IDAO',
                btn: 'Connect wallet'
              },
              footer: {
                whatIs: 'What is this',
                howItWork: 'How it works',
                dao: 'DAO',
                roadMap: 'Roadmap',
                tokenomikcs: 'Tokenomics',
                btn: 'Subscribe',
                emailPlaceholder: 'Email',
                copyrightTitle: '© 2023 IDAO Platform, Inc.',
                copyrightDescription: 'IDAO Platform, Inc. is a financial technology company, not a bank. Banking services are provided by partner banks.',
              }
            }
          },
          ru: {
            translation: {
              header: {
                whatIs: 'Что это',
                howItWork: 'Как работает',
                dao: 'DAO',
                roadMap: 'Дорожная карта',
                tokenomikcs: 'Токеномика',
              },
              main: {
                title: 'Predict Trust Earn',
                description: 'Прогнозируй цены крипто-активов и зарабатывай с помощью IDAO forecast',
                btn:'Описание проекта',
                scrollDownText: 'Безопасная финансовая экосистема',
              },
              aboutUs: {
                title: 'О нас',
                description: 'IDAO – это децентрализованная автономная организация с открытым кодом на базе блокчейна Ethereum для внедрения инвестиционных блокчейн-решений.',
                card1: {
                    title: 'IDAO forecast',
                    description: 'Позволяет делать прогнозы относительно цены актива за вознаграждение (токены IDAO) и рейтинг (статус).'
                },
                card2: {
                    title: 'IDAO trust',
                    description: 'Позволяет выбрать стратегию инвестирования и передать средства в доверительное управление с целью получения прибыли.'
                },
                card3: {
                    title: 'IDAO vote',
                    description: 'Позволяет держателям токенов IDAO совместно принимать ключевые решения и влиять на будущее протокола IDAO.'
                },
              },
              howItWork: {
                title: 'Как это работает',
                block1: {
                    pointTitle: 'Доказательство успеха',
                    title: 'Прогнозы',
                    description: 'Осуществляйте прогнозы на крипто-активы, получайте вознаграждение, формируйте рейтинг и участвуйте в соревнованиях на IDAO forecast.',
                    iconTitle1: 'Актуальная аналитика',
                    iconTitle2: 'Формирование рейтинга',
                    iconTitle3: 'Поощрение участия',
                },
                block2: {
                    pointTitle: 'Инвестиции в рост',
                    title: 'Стейкинг',
                    description: 'Получайте вознаграждение за удержание токенов, доступ к расширенному функционалу IDAO forecast и участию в голосованиях на IDAO Vote.',
                    iconTitle1: 'Участие в развитии',
                    iconTitle2: 'Расширенные возможности',
                    iconTitle3: 'Процентный доход',
                },
                block3: {
                    pointTitle: 'Инвестиции в рост',
                    title: 'Монетизация навыков',
                    description: 'Заявите о своих навыках всему миру, войдите в число лучших аналитиков IDAO forecast и получите возможность стать доверительным управляющим на IDAO trust.',
                    iconTitle1: 'Личный рейтинг',
                    iconTitle2: 'Статус доверительного управляющего',
                    iconTitle3: 'Комиссионный доход',
                },
                block4: {
                    pointTitle: 'Доверительное управление',
                    title: 'Голосования',
                    description: 'IDAO Vote необходимый механизм децентрализованной организации, позволяющий владельцам токенов участвовать в принятии решений.',
                    iconTitle1: 'Децентрализация решений',
                    iconTitle2: 'Личное участие',
                    iconTitle3: 'Защита интересов',
                },
              },
              dao: {
                title: 'DAO и ролевая система',
                description: 'Децентрализованная автономная организация (DAO) - это организация, существующая в блокчейне и функционирующая на базе смарт-контрактов и токена управления, что позволяет участникам принимать решения и управлять организацией без центрального контрагента (органа).',
                block1: {
                    title: 'Контроль',
                    description: 'IDAO в полной мере контролируется владельцами токенов IDAO. Они влияют на направление развития протокола и определяют его основные параметры, которые обеспечивают финансовую стабильность.'
                },
                block2: {
                    title: 'Ролевая система',
                    description: 'IDAO имеет ролевую систему, в основе которой стоят директора, которые вносят предложения на рассмотрение сообщества, а сообщество решает принять или отклонить предложение путем голосования.'
                },
              },
              roadMap: {
                title: 'Дорожная карта',
                desktop: {
                  map1: {
                    block1: {
                      title: '1 квартал 2024 года',
                      description: 'Прототип IDAO Forecast',
                    },
                    block2: {
                      title: '2 квартал 2024 года',
                      description: 'Бета-версия платформы',
                    },
                    block3: {
                      title: '3 квартал 2024 года',
                      description: 'Запуск IDAO Forecast',
                    },
                  },
                  map2: {
                    block1: {
                      title: '4 квартал 2024 года',
                      description: 'Комиссия и пулы ликвидности',
                    },
                    block2: {
                      title: '1-2 квартал 2025 года',
                      description: 'IDAO Trust Beta',
                    },
                    block3: {
                      title: '3 квартал 2025 года',
                      description: 'Запуск IDAO Trust',
                    },
                  },
                },
                mobile: {
                  block1: {
                    title: '1 квартал 2024 года',
                    description: 'Прототип IDAO Forecast',
                  },
                  block2: {
                    title: '2 квартал 2024 года',
                    description: 'Бета-версия платформы',
                  },
                  block3: {
                    title: '3 квартал 2024 года',
                    description: 'Запуск IDAO Forecast',
                  },
                  block4: {
                    title: '4 квартал 2024 года',
                    description: 'Комиссия и пулы ликвидности',
                  },
                  block5: {
                    title: '1-2 квартал 2025 года',
                    description: 'IDAO Trust Beta',
                  },
                  block6: {
                    title: '3 квартал 2025 года',
                    description: 'Запуск IDAO Trust',
                  },
                },
              },
              tokenomikcs: {
                title: 'Токеномика',
                general:'Общее предложение',
                text1: 'Команда',
                text2: 'Сообщество',
                text3: 'Инвесторы',
                text4: 'Маркетинг',
                text5: 'Ставка',
                text6: 'Прогноз',
                text7: 'Другое',
                text8: 'Ликвидность',
              },
              startWorkWith: {
                title: 'Начать работу с IDAO',
                btn: 'Launch App'
              },
              footer: {
                whatIs: 'Что это',
                howItWork: 'Как работает',
                dao: 'DAO',
                roadMap: 'Дорожная карта',
                tokenomikcs: 'Токеномика',
                btn: 'Подписаться',
                emailPlaceholder: 'Email',
                copyrightTitle: '© 2023 IDAO Platform, Inc.',
                copyrightDescription: 'IDAO Platform, Inc. — это финансовая технологическая компания, а не банк. Банковские услуги предоставляются банками-партнерами.',
              }
            }
          },
          es: {
            translation: {
              header: {
                whatIs: '¿Qué es?',
                howItWork: 'Como funciona',
                dao: 'DAO',
                roadMap: 'Hoja de ruta',
                tokenomikcs: 'Tokenómica',
              },
              main: {
                title: 'Predict Trust Earn',
                description: 'Predecir los precios de los cripto activos y gana dinero con IDAO forecast',
                btn:'Descripción del proyecto',
                scrollDownText: 'Ecosistema financiero seguro',
              },
              aboutUs: {
                title: 'Sobre nosotros',
                description: 'IDAO es una organización descentralizada que construye una comunidad y crea un ecosistema para monetizar las habilidades predictivas, proporcionar análisis y servicios de gestión de criptoactivos de confianza.',
                card1: {
                    title: 'IDAO forecast',
                    description: 'Permite realizar predicciones sobre el precio de un activo a cambio de una comisión (tokens IDAO) y una valoración (estado).'
                },
                card2: {
                    title: 'IDAO trust',
                    description: 'Le permite elegir una estrategia de inversión y transferir fondos a la gestión del fideicomiso para obtener beneficios.'
                },
                card3: {
                    title: 'IDAO vote',
                    description: 'Permite a los poseedores de IDAO tokens tomar conjuntamente decisiones clave e influir en el futuro del protocolo IDAO.'
                },
              },
              howItWork: {
                title: 'Cómo funciona',
                block1: {
                    pointTitle: 'Prueba de éxito',
                    title: 'Predicciones',
                    description: 'Haz predicciones sobre criptoactivos, gana recompensas, genera clasificaciones y participa en concursos en IDAO forecast.',
                    iconTitle1: 'Análisis actual',
                    iconTitle2: 'Formación de la clasificación',
                    iconTitle3: 'Fomentar la participación',
                },
                block2: {
                    pointTitle: 'Inversión en el crecimiento',
                    title: 'Steaking',
                    description: 'Reciba recompensas por tener tokens, acceder a la funcionalidad avanzada de IDAO forecast y votar en IDAO Vote.',
                    iconTitle1: 'Participación en el desarrollo',
                    iconTitle2: 'Capacidades avanzadas',
                    iconTitle3: 'Ingresos por intereses',
                },
                block3: {
                    pointTitle: 'Inversión en el crecimiento',
                    title: 'Monetización de competencias',
                    description: 'Reivindique sus habilidades ante el mundo, conviértase en uno de los mejores analistas de IDAO forecast y obtenga la oportunidad de convertirse en administrador de IDAO trust.',
                    iconTitle1: 'Clasificación personal',
                    iconTitle2: 'Estado de fideicomisario',
                    iconTitle3: 'Ingresos por comisiones',
                },
                block4: {
                    pointTitle: 'Gestión de fideicomisos',
                    title: 'Votación',
                    description: 'IDAO Vote es un mecanismo necesario para una organización descentralizada que permite a los poseedores de tokens participar en la toma de decisiones.',
                    iconTitle1: 'Descentralización de las decisiones',
                    iconTitle2: 'Participación personal',
                    iconTitle3: 'Defensa de los intereses',
                },
              },
              dao: {
                title: 'DAO y el sistema de roles',
                description: 'Organización autónoma descentralizada (DAO) es una organización que existe en la blockchain y funciona con base en contratos inteligentes y un token de gestión, lo que permite a los participantes tomar decisiones y gestionar la organización sin una contraparte central (autoridad).',
                block1: {
                    title: 'Control',
                    description: 'IDAO está totalmente controlado por los poseedores de IDAO tokens. Estos influyen en la dirección del desarrollo del protocolo y determinan sus parámetros básicos que garantizan su estabilidad financiera.'
                },
                block2: {
                    title: 'Sistema de roles',
                    description: 'IDAO tiene un sistema de roles basado  en directores que hacen sugerencias a la comunidad, y esta decide aceptar o rechazar la sugerencia mediante votación.'
                },
              },
              roadMap: {
                title: 'Hoja de ruta',
                desktop: {
                  map1: {
                    block1: {
                      title: 'Primer trimestre de 2024',
                      description: 'Prototipo de pronóstico IDAO',
                    },
                    block2: {
                      title: '2 trimestre de 2024',
                      description: 'Versión Beta de la plataforma',
                    },
                    block3: {
                      title: '3 trimestre 2024',
                      description: 'Lanzar pronóstico IDAO',
                    },
                  },
                  map2: {
                    block1: {
                      title: 'Cuarto trimestre de 2024',
                      description: 'Fondos de comisiones y liquidez',
                    },
                    block2: {
                      title: '1-2 trimestre de 2025',
                      description: 'IDAO Trust Beta',
                    },
                    block3: {
                      title: '3 trimestre 2025',
                      description: 'Lanzamiento de IDAO Trust',
                    },
                  },
                },
                mobile: {
                  block1: {
                    title: 'Primer trimestre de 2024',
                    description: 'Prototipo de pronóstico IDAO',
                  },
                  block2: {
                    title: '2 trimestre de 2024',
                    description: 'Versión Beta de la plataforma',
                  },
                  block3: {
                    title: '3 trimestre 2024',
                    description: 'Lanzar pronóstico IDAO',
                  },
                  block4: {
                    title: 'Cuarto trimestre de 2024',
                    description: 'Fondos de comisiones y liquidez',
                  },
                  block5: {
                    title: '1-2 trimestre de 2025',
                    description: 'IDAO Trust Beta',
                  },
                  block6: {
                    title: '3 trimestre 2025',
                    description: 'Lanzamiento de IDAO Trust',
                  },
                },
                
              },
              tokenomikcs: {
                title: 'Tokenómica',
                general:'Oferta general',
                text1: 'Equipo',
                text2: 'Comunidad',
                text3: 'Inversores',
                text4: 'Marketing',
                text5: 'Staking rewards',
                text6: 'Forecast rewards',
                text7: 'Otros (necesidades)',
                text8: 'Liquidez',
              },
              startWorkWith: {
                title: 'Introducción a IDAO',
                btn: 'Conectar la cartera'
              },
              footer: {
                whatIs: '¿Qué es?',
                howItWork: 'Como funciona',
                dao: 'DAO',
                roadMap: 'Hoja de ruta',
                tokenomikcs: 'Tokenómica',
                btn: 'Suscribir',
                emailPlaceholder: 'Email',
                copyrightTitle: '© 2023 IDAO Platform, Inc.',
                copyrightDescription: 'IDAO Platform, Inc. es una empresa de tecnología financiera, no un banco. Los servicios bancarios los prestan bancos asociados.',
              }
            }
          },
      }
  });

export default i18n;