import { styled } from "styled-components";
import { GlobalWrapper } from "../../styles/common-components";
import { colorFetch } from "../../styles/functions";
import { Block } from "./components/Block";
import { dataHowItWorks } from "./data/data";
import { media } from "../../styles/media";
import { useTranslation } from 'react-i18next';
import { uniqueAlphaNumericId } from "../../utils/uniqueId";

const Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: white;
`;

const ContentWrapper = styled(GlobalWrapper)`
  padding-top: 150px;

  ${media.desktop`
    padding-top: 50px;
  `}
`;

const Title = styled.h2`
  color: ${colorFetch("primary")};
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 70px;

  ${media.desktop`
    font-size: 26px;
    margin-bottom: 40px;
  `}
`;

export const HowItWorks = () => {
  const { t } = useTranslation();
  return (
    <Background>
      <ContentWrapper id='howItWork'>
        <Title>{t('howItWork.title')}</Title>
        {dataHowItWorks.map(
          ({list, video, img }, index) => (
            <Block
              key={uniqueAlphaNumericId()}
              label={t(`howItWork.block${index+1}.pointTitle`)}
              title={t(`howItWork.block${index+1}.title`)}
              description={t(`howItWork.block${index+1}.description`)}
              list={list}
              indexBlock={index+1}
              video={video}
              index={index}
              img={img}
            />
          )
        )}
      </ContentWrapper>
    </Background>
  );
};
