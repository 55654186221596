import { styled } from "styled-components";
import { GlobalWrapper } from "../../styles/common-components";
import { colorFetch } from "../../styles/functions";
import background from "../../assets/backgrounds/role_bg.svg";
import { ReactComponent as BackgroundImage } from "../../assets/backgrounds/role_system_bg_image.svg";
import control_icon from "./assets/control_icon.svg";
import roleSystem_icon from "./assets/roleSystem_icon.svg";
import roleImg from "./assets/role_img.svg";
import { TextWithIcon } from "./components/TextWithIcon";
import { media } from "../../styles/media";
import backgroundVideo from "../../assets/backgrounds/dao_system_vid.mp4";
import { useTranslation } from 'react-i18next';
import { useMediaType } from "../../styles/style.context";
import { uniqueAlphaNumericId } from "../../utils/uniqueId";

const Background = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
position: absolute;
z-index: 1;
left: -24px;
bottom: 0;

${media.desktop`
  width: 450px;
  height: auto;
`}

& video::-webkit-media-controls-panel {
  display: none;
  -webkit-appearance: none;
}
& video::-webkit-media-controls-play-button {
  display: none;
  -webkit-appearance: none;
}
& video::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none
}
& video::-webkit-media-controls {
  display: none;
  -webkit-appearance: none;
}
`;

const StyledBackgroundGradient = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  z-index: 1;
  left: -24px;
  bottom: 0;

  ${media.desktop`
    width: 450px;
    height: auto;
  `}
`;

const ContentWrapper = styled(GlobalWrapper)`
  z-index: 2;
  padding-top: 130px;
  padding-bottom: 100px;

  ${media.desktop`
    padding-top: 50px;
    padding-bottom: 236px;
  `}
`;

const FirstPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  ${media.desktop`
    flex-direction: column;
    margin-bottom: 24px;
  `}
`;

const Title = styled.h2`
  max-width: 490px;
  width: 100%;
  color: ${colorFetch("primary")};
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;
  line-height: 72px;

  ${media.desktop`
    font-size: 26px;
    line-height: normal;
  `}
`;

const Description = styled.p`
  max-width: 640px;
  width: 100%;
  color: ${colorFetch("gray1")};
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 22px;

  ${media.desktop`
    font-size: 15px;
    line-height: 150%;
  `}
`;

const SecondPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 76px;

  ${media.desktop`
     gap: 24px;
  `}
`;

const IMG = styled.img`
position: absolute;
z-index: 1;
left: 40px;
bottom: 0;
`

export const RoleSystem = () => {
  const { t } = useTranslation();
  const { desktop } = useMediaType();

  const data = [
    {
      icon: control_icon,
      title: t('dao.block1.title'),
      description: t('dao.block1.description'),
    },
    {
      icon: roleSystem_icon,
      title:  t('dao.block2.title'),
      description: t('dao.block2.description'),
    },
  ];

  return (
    <Background>
      <ContentWrapper id='dao'>
        <FirstPart>
          <Title>{t('dao.title')}</Title>
          <Description>
          {t('dao.description')}
          </Description>
        </FirstPart>
        <SecondPart>
          {data.map(({ icon, title, description }) => (
            <TextWithIcon key={uniqueAlphaNumericId()} icon={icon} title={title} description={description} />
          ))}
        </SecondPart>
      </ContentWrapper>
      <StyledBackgroundGradient src={background} />
    <Video autoplay='autoplay' loop='loop' muted='muted' loading="lazy" playsinline='' style={{pointerEvents: 'none'}}>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>   
    </Background>
  );
};
