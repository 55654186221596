import { styled } from "styled-components";
import { GlobalWrapper } from "../../styles/common-components";
import { Card } from "./component/Card";
import { colorFetch } from "../../styles/functions";
import { media } from "../../styles/media";
import { useTranslation } from 'react-i18next';
import { uniqueAlphaNumericId } from "../../utils/uniqueId";

import video1 from "./assets/video1.mp4";
import video2 from "./assets/video2.mp4";
import video3 from "./assets/video3.mp4";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";

const Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    146deg,
    ${colorFetch("secondary")} 100%,
    rgba(235, 245, 244, 0.49) 100%
  );
`;

const ContentWrapper = styled(GlobalWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    align-items: flex-start;
  `}
`;

const Title = styled.h2`
  margin-top: 130px;
  color: ${colorFetch("primary")};
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-weight: 600;

  ${media.desktop`
    margin-top: 50px;
    text-align: start;
    font-size: 26px;
    font-weight: 600;
  `}
`;

const Description = styled.p`
  margin-top: 30px;
  max-width: 878px;
  width: 100%;
  color: ${colorFetch("gray1")};
  text-align: center;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  ${media.desktop`
    margin-top: 16px;
    text-align: start;
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
  `}
`;

const Cards = styled.div`
  margin: 100px 0 114px;
  display: flex;
  gap: 34px;

  ${media.desktop`
    margin: 24px 0 50px;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const AboutUs = () => {
  const { t } = useTranslation();
  const cards = [
    {
      video: video1,
      title: "IDAO forecast",
      text: t('aboutUs.card1.description'),
      img: img1,
    },
    {
      video: video2,
      title: "IDAO trust",
      text: t('aboutUs.card2.description'),
      img: img2,
    },
    {
      video: video3,
      title: "IDAO vote",
      text: t('aboutUs.card3.description'),
      img: img3,
    },
  ];

  return (
    <Background>
      <ContentWrapper id='whatIs'>
        <Title>{t('aboutUs.title')}</Title>
        <Description>
        {t('aboutUs.description')}
        </Description>
        <Cards>
          {cards.map(({ video, title, text, img }) => (
            <Card key={uniqueAlphaNumericId()} video={video} title={title} text={text} img={img} />
          ))}
        </Cards>
      </ContentWrapper>
    </Background>
  );
};
