import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { ReactComponent as IDAOLogoIcon } from "../../assets/images/IDAO_logo.svg";
import { ReactComponent as LangIcon } from "../../assets/images/planet.svg";
import { links } from "../../data/data";
import { GlobalWrapper } from "../../styles/common-components";
import { colorFetch } from "../../styles/functions";
import { media } from "../../styles/media";
import { ReactComponent as BurgerButtonIcon } from "./assets/burger_icon.svg";
import { ReactComponent as CloseButtonIcon } from "./assets/close_icon.svg";
import { ConnectWalletButton } from "./components/ConnectWalletButton";
import { MobileMenu } from "./components/MobileMenu";
import { useBlockBodyScroll } from "../../utils/useBlockBodyScroll";
import { useMediaType } from "../../styles/style.context";
import { Link } from 'react-scroll';
import { useTranslation, Trans } from 'react-i18next';
import { lngs } from '../../app/App';
import { ButtonState } from "../../styles/common-components";


const Button = styled(ButtonState)`
  // ${media.desktop`
//   display: none;
// `}
`;

const Background = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 120px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &.scrolled {
    background-color: rgb(9 54 63 / 80%);
    backdrop-filter: blur(20px);
  }

  ${media.desktop`
    height: 80px;
  `}
`;

const ContentWrapper = styled(GlobalWrapper)`
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;

  ${media.desktop`
    justify-content: space-between;
  `}
`;

const StyledIDAOLogoIcon = styled(IDAOLogoIcon)`
  margin-right: 84px;
`;

const Links = styled.div`
  flex: 1;
  display: flex;
  gap: 30px;

  ${media.desktop`
    display: none;
  `}
`;

const LinkWrapper = styled.span`
  cursor: pointer;
  color: ${colorFetch("secondary")};
  font-family: HelveticaNeueCyr;
  font-size: 14px;
  font-weight: 500;
`;

const WalletBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;



export const StyledConnectWalletButton = styled(ConnectWalletButton)`
  & > svg > path {
    fill: ${colorFetch("primary")};
  }

  ${media.desktop`
    display: none;
  `}
`;

const StyledBurgerButtonIcon = styled(BurgerButtonIcon)`
  cursor: pointer;
`;

const StyledCloseButtonIcon = styled(CloseButtonIcon)`
  cursor: pointer;
`;

const DesktopCloseButtonIcaon = styled(StyledCloseButtonIcon)`
z-index: 1;
`

const ModalLang = styled.div`
position: absolute;
width: 20%;
height: 5vh;
top: 75%;
left: 77%;
transform: translateX(-100%);
transition: all 0.1s linear;
background: rgba(9, 54, 63, 0.9);
backdrop-filter: blur(7px);
-webkit-backdrop-filter: blur(7px);
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 30px 16px 50px;
border: 2px solid #ffffff;
border-radius: 50px;

  &.open {
    transform: translateY(0);
  }
`;

export const StyledIconLink = styled(Link)`
cursor: pointer;
`

const StyledMadalLangIcon = styled(LangIcon)`
cursor: pointer;
`
const StyledLinks = styled(Links)`
cursor: pointer;
color: #DEF7F3;
`

export const Header = () => {
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenModalLang, setIsOpenModelLang] = useState(false);
  const { i18n } = useTranslation();

  const { desktop } = useMediaType();

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.currentTarget.scrollY > 50) {
        setVisibleHeader(true);
      } else {
        setVisibleHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Background className={!isOpenMobileMenu && visibleHeader && "scrolled"}>
      <ContentWrapper>
        <StyledIconLink smooth={true} duration={500} to={'main'}>
        <StyledIDAOLogoIcon />
        </StyledIconLink>
        
        <Links>
          {links.map(({ text, url }, index) => {
            return (
            <LinkWrapper key={index}>
              <Link smooth={true} duration={500} to={url}><Trans i18nKey={`header.${url}`}>{text}</Trans></Link>
            </LinkWrapper>
          )})}
        </Links>
        <WalletBlock>

          {!desktop && ( 
            isOpenModalLang ? (
                <DesktopCloseButtonIcaon
                  onClick={() => {
                    setIsOpenModelLang(false);
                  }}
                />
              ) : (
                <StyledMadalLangIcon
                  onClick={() => {
                    setIsOpenModelLang(true);
                  }}
                />
              ))
            }
          {
            isOpenModalLang && (
                  <ModalLang className={isOpenModalLang && "open"}>
                    <StyledLinks>
                        {Object.keys(lngs).map((locale) => (<div onClick={() => {
                         
                           i18n.changeLanguage(locale)
                           setIsOpenModelLang(false);
                           }}>
                            {lngs[locale].nativeName}
                          </div>))}
                    </StyledLinks>
                  </ModalLang>)}
          <a style={{textDecorationLine: 'none', color: '#09363F'}} target="blank" download="whitepaper" href="https://forecast.idao.finance/">
            <Button>
                  Launch app
            </Button>
          </a>
          {desktop && (
            <>
              {isOpenMobileMenu ? (
                <StyledCloseButtonIcon
                  onClick={() => {
                    setIsOpenMobileMenu(false);
                  }}
                />
              ) : (
                <StyledBurgerButtonIcon
                  onClick={() => {
                    setIsOpenMobileMenu(true);
                  }}
                />
              )}
            </>
          )}
        </WalletBlock>
      </ContentWrapper>
      <MobileMenu setIsOpenMobileMenu={setIsOpenMobileMenu} links={links} isOpenMobileMenu={isOpenMobileMenu} />
    </Background>
  );
};
